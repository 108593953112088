<template>
  <a-modal
    :visible="visibleInvitationModal"
    @cancel="closevisibleInvitationModal"
    :footer="null"
    wrapClassName="invite-to-glocal-modal"
  >
    <div class="invite-glocal">
      <h2 class="invite-glocal__title">
        Invite Others to Glocal
      </h2>
      <!-- <tagify-email
        placeholder="Invite Via Email"
        @fetchEmail="fetchedEmail"
        :isSubmitted="isSubmit"
      ></tagify-email> -->
      <form class="invite-glocal__form" @submit.prevent="inviteGlocal">
        <input type="email" v-model="email" placeholder="Enter email" />
      </form>
      <p v-if="FieldErr" class="invite-glocal__error">{{ errorMessage }}</p>
      <a-button
        :loading="loading"
        class="invite-glocal__btn"
        @click="inviteGlocal"
        >Send</a-button
      >
    </div>
  </a-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import MessageToaster from "../BaseComponents/MessageToaster.vue";
// import TagifyEmail from "../PdbComponents/TagifyEmail.vue";
export default {
  props: {
    visibleModal: Boolean,
  },
  watch: {
    visibleModal() {
      this.visibleInvitationModal = this.visibleModal;
    },
    email(value) {
      if (value) {
        this.FieldErr = false;
      }
    },
  },
  data() {
    return {
      visibleInvitationModal: false,
      showMessageModal: false,
      message: {},
      email: "",
      isSubmitted: false,
      FieldErr: false,
      loading: false,
      errorMessage: "",
    };
  },
  components: {
    MessageToaster,
    // TagifyEmail,
  },
  computed: {
    isSubmit() {
      return this.isSubmitted;
    },
  },
  emits: ["closeModal"],
  methods: {
    closevisibleInvitationModal() {
      this.FieldErr = false;
      this.loading = false;
      this.errorMessage = "";
      this.email = "";
      this.$emit("closeModal");
    },
    // fetchedEmail(val) {
    //   const emails = val.filter((value)=> {
    //     return value;
    //   })
    //   console.log("this is filter", emails);
    //   console.log("this is val" , val)
    //   this.emails = val;
    // },
    async inviteGlocal() {
      try {
        if (this.email === "") {
          this.FieldErr = true;
          this.errorMessage = "This field is required.";
          return false;
        }
        // const payload = this.email
        //   .filter((val) => val !== undefined && val !== null && val !== "")
        //   .map((val) => ({ email: val }));
        const payload = {
          email: this.email,
        };
        this.loading = true;

        if (!this.FieldErr) {
          const response = await this.$store.dispatch("auth/inviteOnGlocal", [
            payload,
          ]);

          if (response.status === 200) {
            this.showMessageModal = true;
            this.message = {
              title: "Email has been sent",
              type: "success",
            };
            this.closevisibleInvitationModal();
          }
        }
      } catch (err) {
        this.loading = false;
        this.FieldErr = true;
        this.errorMessage = err.response.data[0].email[0];
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.invite-to-glocal-modal {
  .ant-modal {
    width: 50rem !important;
    top: 18rem;
    .ant-modal-content {
      padding: 2.5rem;
      box-shadow: 0px 0px 94px #00000047;
      background: #f6f8ff;
      border-radius: 0;
      .ant-modal-close {
        margin: 3rem;
        .ant-modal-close-x {
          width: fit-content;
          height: fit-content;
          line-height: 0;
        }
      }
      .ant-modal-header {
        display: none;
      }
      .ant-modal-body {
        .invite-glocal {
          &__title {
            font-size: 2.4rem;
            font-family: $font-secondary-bold;
            line-height: normal;
            margin-bottom: 2.5rem;
          }
          &__error {
            color: red;
            font-size: 1.5rem;
            font-family: $font-secondary;
            margin: 0.5rem 0 0;
          }
          &__btn {
            font-size: 1.5rem;
            font-family: $font-primary-medium;
            width: 10rem;
            margin-top: 4rem;
            margin-left: 78%;
            height: 4rem;
            border: none;
            background-color: $color-primary;
            color: $color-white;
            padding: 0;
            span {
              line-height: normal;
            }
          }
          &__form {
            input {
              color: $color-black;
              font-size: 1.4rem;
              font-family: $font-secondary;
              border: 1px solid hsla(0, 0%, 43.9%, 0.2);
              width: 100%;
              padding: 0.6rem 1.2rem;
              outline: none;
              border-radius: 0;
              background-color: $color-white;
              &::placeholder {
                opacity: 0.4;
              }
              @include respond(tab-port) {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
